import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function createInvoice(data) {
  try {
    const response = await baseAxios.post("/invoiceWeb/addInvoice", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getInvoice(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/invoiceWeb/getInvoice?page=${data.page}&limit=${data.limit}&cari=${data.cari}&idSo=${data.idSo}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLINVOICE", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function updateInvoice(data) {
  try {
    const response = await baseAxios.post("/invoiceWeb/editInvoice", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cetakInv(data) {
  try {
    const response = await baseAxios(
      `/invoiceWeb/cetakInvPdf?idInvoice=${data.idInv}&idSo=${data.idSo}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function kirimEmailInv(data) {
  try {
    const response = await baseAxios.post("/invoiceWeb/kirimEmailInv", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function hapusInv(data) {
  try {
    const response = await baseAxios.post("/invoiceWeb/hapusInvoice", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}
